import { IConfigsType } from '../../types'

const fwSystemwideOverviewConfig: IConfigsType = {
  fw_wingstop: {
    '/:brand/systemwide_overview': {
      variables: ['corporateGroup'],
      groupFilter: {
        api: 'corporateFilter',
        defaultValue: ['Market', 'Market Breakdown'],
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'custom',
        ],
      },
      systemwideOverview: {
        type: 'list',
        api: 'fwWingstopSystemwideOverview',
        title: 'Systemwide Overview',
        fieldWidth: '120px',
        fields: [
          {
            content: {
              key: 'netSales',
            },
            bottom: 'Net Sales',
          },
          {
            content: {
              key: 'netSalesPercentageDiffToLastYear',
              decimal: 1,
            },
            bottom: 'SSS %',
          },
          {
            content: {
              key: 'salesVariancePercentage',
              decimal: 1,
            },
            bottom: 'Sales - Variance to Forecast',
          },
          {
            content: {
              key: 'checkCount',
            },
            bottom: 'Check Count',
          },
          {
            content: {
              key: 'checkAverage',
              decimal: 2,
            },
            bottom: 'Check Average',
          },
          {
            content: {
              key: 'laborActualPercent',
              decimal: 1,
            },
            bottom: 'Hourly Labor Percent',
          },
          {
            content: {
              key: 'laborVariancePercent',
              decimal: 1,
              color: '<%- value > 0 ? colors.Pomodoro : "" %>',
            },
            bottom: 'Labor - Variance to Scheduled',
          },
          {
            content: {
              key: 'actualFoodCostPercentage',
              decimal: 1,
            },
            bottom: 'COGS',
          },
          {
            content: {
              key: 'varFoodCostPercentage',
              decimal: 1,
            },
            bottom: 'COGS - Variance to Theoretical',
          },
        ],
      },
      breakdownByStore: {
        type: 'table-v2',
        title: 'Breakdown by Store',
        api: 'fwWingstopSystemwideOverview',
        pagination: {
          pageSize: 20,
        },
        sorting: [
          {
            id: 'netSales',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'sales',
            title: 'Net Sales',
            align: 'center',
            children: [
              {
                key: 'netSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesVariancePercentage',
                title: 'Sales - Variance to Forecast',
                align: 'center',
                sortable: true,
                width: '80px',
                decimal: 1,
              },
              {
                key: 'netSalesPercentageDiffToLastYear',
                title: 'SSS%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
          {
            key: 'hourlyLabor',
            title: 'Hourly Labor',
            align: 'center',
            children: [
              {
                key: 'laborActualPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'laborVariancePercent',
                title: 'Labor - Variance to Scheduled',
                align: 'center',
                sortable: true,
                width: '80px',
                decimal: 1,
                color: '<%- value > 0 ? colors.Pomodoro : "" %>',
              },
            ],
          },
          {
            key: 'cogs',
            title: 'COGS',
            align: 'center',
            children: [
              {
                key: 'actualFoodCostPercentage',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'varFoodCostPercentage',
                title: 'COGS - Variance to Theoretical',
                align: 'center',
                sortable: true,
                width: '80px',
                decimal: 1,
              },
            ],
          },
        ],
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
      },
    },
  },
}

export default fwSystemwideOverviewConfig
