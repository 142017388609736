import { useMemo } from 'react'

import { corporateGroupTableConfigs } from '../../../variables'
import { IApiDataType } from '../../types'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const fwWingstopSystemwideOverviewConfigs = {
  ...corporateGroupTableConfigs,
  netSales: 'price',
  netSalesPercentageDiffToLastYear: 'percent',
  salesVariancePercentage: 'percent',
  laborActualPercent: 'percent',
  laborVariancePercent: 'percent',
  actualFoodCostPercentage: 'percent',
  varFoodCostPercentage: 'percent',
} as const

const useCommonFwWingstopSystemwideOverview = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'net_sales',
      'sales_variance_percentage',
      'net_sales_percentage_diff_to_last_year',
      'labor_actual_percent',
      'labor_variance_percent',
      'actual_food_cost_percentage',
      'var_food_cost_percentage',
    ],
  },
})

const useFwWingstopSystemwideOverview = () => {
  const { data, loading } = useCommonFwWingstopSystemwideOverview()

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return data

      const adjustedData = data.map((d) => ({
        ...d,
        laborVariancePercent: (d.laborVariancePercent as number) * -1,
      }))
      return adjustedData
    }, [data]),
    loading: loading,
  }
}

export default useFwWingstopSystemwideOverview
